<template>
    <div class="dd-detail" >
        <div v-if="!isMobile" class="add-tonnage">
            <div class="detail-left">
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header" v-if="isEorD === '2' && this.tonnageForm.myFlag && !extract ">
                            <el-col :span="10"><span>{{$t('message.addtonnage.name')}}</span></el-col>
                            <el-col :span="14">
                              <el-button-group class="ml-4">
                                <el-button
                                    v-show="true"
                                    size="small"
                                    :disabled="editdisabled"
                                    @click="go_edit(tonnage_id)">{{$t('message.edit')}}
                                </el-button>
                                <el-button
                                    v-show="true"
                                    size="small" type="danger"
                                    :disabled="deletedisabled"
                                    @click="open_Del(tonnage_id)">{{$t('message.delete')}}
                                </el-button>
                                <el-button
                                    v-show="true"
                                    size="small" type="success"
                                    :disabled="matchdisabled"
                                    @click="go_match(tonnageForm)" plain>{{$t('message.matching')}}
                                </el-button>
                                <el-button
                                    v-show="true"
                                    size="small" type="primary"
                                    @click="handleDelete(scope.$index, scope.row)">{{$t('message.original')}}
                                </el-button>
                              </el-button-group>
                             </el-col>
                            <div v-if="isEorD === '2'"  class="collect">
                                <star-box :fontSize="starFontSize"></star-box>
                            </div>
                        </div>
                      <div class="card-header" v-else-if="isEorD === '2' && this.tonnageForm.myFlag && extract ">
                        <el-col :span="15"><span>{{$t('message.addtonnage.name')}}</span></el-col>
                        <el-col :span="9">
                          <el-button-group class="ml-4">
                            <el-button
                                v-show="true"
                                size="small"
                                @click="go_editextract(tonnage_id)">{{$t('message.edit')}}
                            </el-button>
                            <el-button
                                v-show="true"
                                size="small" type="success"
                                @click="addtonnage()" >{{$t('message.sidebar.issue')}}
                            </el-button>
                            <el-button
                                v-show="true"
                                size="small" type="primary"
                                @click="gotolist()" >{{$t('message.back')}}
                            </el-button>
                        </el-button-group>
                        </el-col>
                        <div v-if="isEorD === '2'"  class="collect">
                          <star-box :fontSize="starFontSize"></star-box>
                        </div>
                      </div>
                      <div class="card-header" v-else-if="isEorD === '1' && this.tonnageForm.myFlag && extract ">
                        <el-col :span="20"><span>{{$t('message.addtonnage.name')}}</span></el-col>
                        <el-col :span="4">
                          <el-button-group class="ml-4" style="display: flex;">
                            <!--<el-button  size="small"  style="border-radius: 2px;border-color: lightblue" @click="handleModify" >{{$t('message.tonnage.aishibie')}}</el-button>-->
                            <el-button
                                v-show="true"
                                size="small" type="primary"
                                @click="gotolist()" 
                               
                              
                                >{{$t('message.back')}}
                            </el-button>
                          </el-button-group>
                          
                        </el-col>
                      </div>
                        <div class="card-header" v-else >
                            <el-col :span="19"><span>{{$t('message.addtonnage.name')}}</span></el-col>
                            <el-col  :span="5">
                            <el-button v-show="isEorD !== '2'&&!extract"  @click="openemailextract" type="text" size="mini">{{$t('message.email.seletemail')}}</el-button></el-col>
                            <div v-if="isEorD === '2'"  class="collect">
                                <star-box :fontSize="starFontSize"></star-box>
                            </div>
                        </div>
                    </template>
                  <el-drawer v-model="draweroriginal" :title="$t('message.email.emailoriginalcontent')" style="overflow: auto" size="50%">
                    <div>
                      <el-form class="user-account-key"  v-model="emailcontextForm" >
                        <el-form-item  prop="emailcontext">
                          <el-input type="textarea" v-model="emailcontextForm.email_content" :autosize="{ minRows: 10,maxRows:20  }"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-drawer>
<!--                  <el-drawer v-model="drawer" :title="$t('message.email.please_input')" style="overflow: auto" size="50%">-->
<!--                    <div>-->
<!--                      <el-form class="user-account-key"  v-model="emailcontextForm" label-width="100px">-->
<!--                        <el-form-item :label="$t('message.email.email_content')" prop="emailcontext">-->
<!--                          <el-input type="textarea" v-model="emailcontextForm.email_content" :autosize="{ minRows: 10,maxRows:20  }"></el-input>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item id="btn-box">-->
<!--                          <el-button type="primary"  @click="extractemail">{{$t('message.email.extract')}}</el-button>-->
<!--                          <el-button @click="emailcontextForm.email_content=''">{{$t('message.email.clear')}}</el-button>-->
<!--                        </el-form-item>-->
<!--                      </el-form>-->
<!--                    </div>-->
<!--                  </el-drawer>-->
                    <div class="cardcontent">
                        <el-form v-if="isEorD !== '2'&&!extract" ref="tonnageForm" label-width="100px" :model="tonnageForm" :rules="rules" size="mini" >
                            <el-form-item :label="$t('message.addtonnage.shipname')" prop="shipVesselName">
                                <el-autocomplete
                                        v-model="tonnageForm.shipVesselName"
                                        name="shipVesselCname"
                                        style="width: 75%;"
                                        :fetch-suggestions="querySearchVessel"
                                        :trigger-on-focus="false"
                                        @select="selectvessel"
                                        :placeholder="$t('message.addtonnage.shipname')"
                                        @change="changevesellname"
                                        
                                >
                                    <template #default="{ item }">
                                        <el-row>
                                            <el-col :span="15"><span class="value">{{ item.value }}</span></el-col>
                                            <el-col :span="9">
                                              <span v-if="item.id != -1" class="link" style="font-size:smaller">{{ item.built }}/{{ item.dwt }}</span>
                                              <span v-else class="link" style="font-size:smaller"></span>
                                            </el-col>
                                        </el-row>
                                    </template>
                                </el-autocomplete>
                              <el-tooltip
                                  class="item"
                                  effect="light"
                                  content="找不到结果请自己添加船舶规范"
                                  placement="right-start"
                              >
                                   <el-button size="mini" circle  icon="el-icon-plus" @click="drawer = true"></el-button>
                               </el-tooltip>
                                    <el-drawer v-model="drawer" title="I am the title" :with-header="false" size="40%" >
                                      <div style="height: 100%">
                                        <Ship class="addship" v-on:guanbi="guanbi" style="height: 100%; overflow-y: auto"></Ship>
                                      </div>
                                    </el-drawer>
<!--                              <span style="border:1px solid;border-color:rgb(220,223,231);padding: 4px;margin-left:4px;" v-show="tonnageForm.built!==''&&tonnageForm.shipVesselName!==''">{{ tonnageForm.built }}</span>
                          -->  </el-form-item>
                          <el-form-item :label="$t('message.addtonnage.dwt')" prop="dwt" required>
                            <el-input
                                :placeholder="$t('message.addtonnage.dwt')"
                                v-model.number="tonnageForm.dwt"
                                class="input-with-select"
                                style="width:80%"
                                type="number"
                                :min="0" oninput="if(value<0)value=0"
                            >
                              <template #append>
                                {{$t('message.T')}}
                              </template>
                            </el-input>
                          </el-form-item>
                          <el-form-item :label="$t('message.addtonnage.build')" prop="built" required>
                            <el-date-picker
                                v-model="tonnageForm.built"
                                type="year"
                                :placeholder="$t('message.addtonnage.pickyear')"
                            />

                          </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.opendate')" required>
                                <el-col :span="11">
                                    <el-form-item prop="openStartDate">
                                        <el-date-picker type="date"
                                                        value-format="yyyy-MM-dd"
                                                        :placeholder="$t('message.search.startdate')"
                                                        v-model="tonnageForm.openStartDate"
                                                        style="width: 100%;">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="2">--</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="openEndDate">
                                        <el-date-picker :placeholder="$t('message.search.enddate')"
                                                        type="date"
                                                        value-format="yyyy-MM-dd"
                                                        v-model="tonnageForm.openEndDate"
                                                        style="width: 100%;">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.openarea')" required >
                                <el-col :span="11">
                                    <el-form-item prop="openSeaareaName">
                                        <el-autocomplete  v-model="tonnageForm.openSeaareaName"
                                                         :placeholder="$t('message.addtonnage.openarea')"
                                                         style="width: 100%;"
                                                         :fetch-suggestions="querySearchOpenSea"
                                                         :trigger-on-focus="true"
                                                         @select="selectopensea"
                                                         @change="changeopenSeaareaName"
                                        >
                                        </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="2">--</el-col>
                                <el-col :span="11">
                                    <el-form-item  prop="openPortName">
                                        <el-autocomplete v-model="tonnageForm.openPortName"
                                                         :placeholder="$t('message.tonnage.openPort')"
                                                         style="width: 100%;"
                                                         :fetch-suggestions="querySearchOpenPort"
                                                         :trigger-on-focus="true"
                                                         @select="selectopenport"
                                                         @change="changeopenport"
                                        >
                                        </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item :label="$t('message.addtonnage.intent_country')" prop="intentCountryName">
                                        <el-autocomplete v-model="tonnageForm.intentCountryName"
                                                         :placeholder="$t('message.addtonnage.intent_country')"
                                                         style="width: 95%;"
                                                         :fetch-suggestions="querySearchIntentCountry"
                                                         :trigger-on-focus="false"
                                                         @select="selectintentcountry"
                                                         @change="changeintentcountry"
                                        >
                                        </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item :label="$t('message.addtonnage.intent_cargo')" prop="cargoName">
                                        <el-autocomplete v-model="tonnageForm.cargoName"
                                                         :placeholder="$t('message.addtonnage.intent_cargo')"
                                                         style="width: 100%;"
                                                         :fetch-suggestions="querySearchIntentCargo"
                                                         :trigger-on-focus="false"
                                                         @select="selectintentcargo"
                                                         @change="changeintentcargo"
                                        >
                                        </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="1">
                                    <el-tooltip
                                            class="item"
                                            effect="light"
                                            content="找不到结果请自己添加货名"
                                            placement="right-start"
                                    >
                                        <el-button circle size="mini" icon="el-icon-plus" @click="cargonamedialog = true"></el-button>
                                    </el-tooltip>
                                </el-col>
                                <CDialog @hidecargodialog="hidecargodialog" v-on:intentcargo="changecargoname" :cargonamedialog="cargonamedialog"></CDialog>

                            </el-row>
                            <el-form-item :label="$t('message.addtonnage.intent_area')" >
                                <el-col :span="12" >
                                    <el-form-item prop="intentSeaareaName">
                                    <el-autocomplete v-model="tonnageForm.intentSeaareaName"
                                                     :placeholder="$t('message.addtonnage.intent_area')"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchIntentSea"
                                                     :trigger-on-focus="false"
                                                     @select="selectintentsea"
                                                     @change="changeintentSeaarea"
                                    >
                                    </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="2">--</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="intentPortName">
                                    <el-autocomplete v-model="tonnageForm.intentPortName"
                                                     :placeholder="$t('message.addtonnage.intent_port')"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchIntentPort"
                                                     :trigger-on-focus="true"
                                                     @select="selectintentport"
                                                     @change="changeintentport"
                                    >
                                    </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item :label="$t('message.addtonnage.sender')"
                                                  prop="senderEmail">
                                        <el-input v-model="tonnageForm.senderEmail"
                                                         :placeholder="$t('message.addtonnage.sender')"
                                                         style="width: 100%;">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('message.addtonnage.sendtime')"
                                                  prop="sendTime">
                                        <el-date-picker
                                                value-format="yyyy-MM-dd"
                                                :disabled-date="disabledDate"
                                                type="datetime"
                                                :placeholder="$t('message.addtonnage.sendtime')"
                                                v-model="tonnageForm.sendTime"
                                                style="width: 100%;">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item :label="$t('message.addtonnage.memo')" prop="memo">
                                <el-input type="textarea" v-model="tonnageForm.memo" :placeholder="$t('message.addtonnage.please_memo')" :rows="3"></el-input>
                            </el-form-item>
                            <!--<el-checkbox-group v-model="tonnageForm.extra" prop="extra">-->
                            <el-row>
                                <el-col :span="3"></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.pPT" :checked="tonnageForm.pPT" :label="$t('message.addtonnage.sszz')" name="PPT" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.rVTG" :checked="tonnageForm.rVTG" :label="$t('message.addtonnage.shgz')" name="RVTG" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.fORTC" :checked="tonnageForm.fORTC" :label="$t('message.addtonnage.tyzq')" name="FORTC" ></el-checkbox></el-col>
                            </el-row>
                            <!--</el-checkbox-group>-->
                            <!--:on-success="uploadSuccess"-->
                            <!--ref="upload"-->
<!--                          action="http://localhost:9528/api/tonnage/accessoryUpload"-->
                            <el-row>
                                <el-col :span="3">
                                </el-col>
                                <el-col :span="20">
                                    <el-upload
                                            ref="upload"
                                            class="upload-demo"
                                            :limit=limitNum
                                            multiple
                                            :on-exceed="exceedFile"
                                            :headers="headerObj"
                                            :on-error="loadError"
                                            :on-preview="handlePreview"
                                            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.doc,.pdf,.xls,.docx,.xlsx"
                                            :on-change="handleChange"
                                            :on-remove="handleMove"
                                            :before-remove="beforeRemove"
                                            :file-list="fileList"
                                            :auto-upload="false">
                                          <el-button  size="small" type="primary" style="margin-top: 20px;">{{$t('message.file.selectfile')}}</el-button>
                                          <template #tip>
                                            <div  style="color:forestgreen;margin:-35px 0 0 120px ;height:35px;display: flex">
                                              <table>
                                                <tr><td style="margin: auto">{{$t('message.file.supportfile')}}</td></tr>
                                              </table>
                                            </div>
                                          </template>
                                    </el-upload>
                                </el-col>
                            </el-row>
                            <el-form-item id="btn-box2" size="medium" style="margin-top: 20px;">
                                <el-button v-if="isEorD === '1'&&!extract"  type="primary" @click="openedit" :disabled="disabled">{{$t('message.addtonnage.modify')}}</el-button>
<!--                                <el-button v-else-if="isEorD === '1'&& extract" style="margin-left: 103px"  type="primary" @click="saveextract" >{{$t('message.save')}}</el-button>-->
                                <el-button v-else  type="primary" @click="addtonnage()" :disabled="disabled">{{$t('message.addtonnage.addtonnage')}}</el-button>
                                <el-button v-if="!extract" style="margin-left: 137px" @click="reset">{{$t('message.addtonnage.reset')}}</el-button>
                            </el-form-item>
                            <Dialog @hidedialog="hidedialog" :dialogVisible="dialogVisible"></Dialog>
                        </el-form>
                      <el-form v-if="isEorD !== '2'&&extract" ref="tonnageForm" label-width="100px" :model="tonnageForm" :rules="rules" size="mini" >
                        <el-form-item :label="$t('message.addtonnage.shipname')" prop="shipVesselName">
                          <el-autocomplete
                              v-model="tonnageForm.shipVesselName"
                              name="shipVesselCname"
                              style="width: 75%;"
                              :fetch-suggestions="querySearchVessel"
                              :trigger-on-focus="false"
                              @select="selectvessel"
                              :placeholder="$t('message.addtonnage.shipname')"
                              @change="changevesellname"
                              @mousedown="handleMouseEnter('shipVesselName')"
                              @mouseup="clearHighlight('shipVesselName')"
                          >
                            <template #default="{ item }">
                              <el-row>
                                <el-col :span="15"><span class="value">{{ item.value }}</span></el-col>
                                <el-col :span="9">
                                  <span v-if="item.id != -1" class="link" style="font-size:smaller">{{ item.built }}/{{ item.dwt }}</span>
                                  <span v-else class="link" style="font-size:smaller"></span>
                                </el-col>
                              </el-row>
                            </template>
                          </el-autocomplete>
                          <el-tooltip
                              class="item"
                              effect="light"
                              content="找不到结果请自己添加船舶规范"
                              placement="right-start"
                          >
                          <el-button size="mini" circle  icon="el-icon-plus" @click="drawer = true"></el-button>
                         
                         
                          </el-tooltip>
                         
                          <el-drawer v-model="drawer" title="I am the title" :with-header="false" size="40%" >
                            <div style="height: 100%">
                              <Ship class="addship" v-on:guanbi="guanbi" style="height: 100%; overflow-y: auto"></Ship>
                            </div>
                          </el-drawer>
                          <!--                              <span style="border:1px solid;border-color:rgb(220,223,231);padding: 4px;margin-left:4px;" v-show="tonnageForm.built!==''&&tonnageForm.shipVesselName!==''">{{ tonnageForm.built }}</span>
                                                    -->
                        </el-form-item>
                         <!--<div class=" right-panel">
  <div v-html="removeNonBreakingSpaces(highlightedContent)" style="white-space: pre-wrap; overflow-y: auto;"></div>
</div>-->
                        <el-form-item :label="$t('message.addtonnage.dwt')" prop="dwt" required>
                          <el-input
                              :placeholder="$t('message.addtonnage.dwt')"
                              v-model.number="tonnageForm.dwt"
                              class="input-with-select"
                              style="width:75%"
                              type="number"
                              :min="0" oninput="if(value<0)value=0"
                              
                              @mousedown="handleMouseEnter('dwt')"
                              @mouseup="clearHighlight('dwt')"
                          >
                            <template #append>
                              {{$t('message.T')}}
                            </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.build')" prop="built" required>
                          <div @mousedown="handleMouseEnter('built')" @mouseup="clearHighlight('built')">
  <el-date-picker
    v-model="tonnageForm.built"
    type="year"
    :placeholder="$t('message.addtonnage.pickyear')"
  />
</div>
                          <!--<el-date-picker
                              v-model="tonnageForm.built"
                              type="year"
                              :placeholder="$t('message.addtonnage.pickyear')"
                              @mouseenter="handleMouseEnter('built')"
                              @mouseleave="clearHighlight('built')"
                          />-->

                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.opendate')" required>
                          <el-col :span="11">
                            <el-form-item prop="openStartDate">
                            <div @mousedown="handleMouseEnter('openStartDate')"
                                              @mouseup="clearHighlight('openStartDate')">
                              <el-date-picker type="date"
                                              value-format="yyyy-MM-dd"
                                              :placeholder="$t('message.search.startdate')"
                                              v-model="tonnageForm.openStartDate"
                                              style="width: 100%;"
                                              @mousedown="handleMouseEnter('openStartDate')"
                                              @mouseup="clearHighlight('openStartDate')"
                              >
                              </el-date-picker>
                            </div>
                            </el-form-item>
                          </el-col>
                          <el-col class="line" :span="2">--</el-col>
                          <el-col :span="11">
                            <el-form-item prop="openEndDate">
                              <div @mousedown="handleMouseEnter('openEndDate')"
                                              @mouseup="clearHighlight('openEndDate')">
                              <el-date-picker :placeholder="$t('message.search.enddate')"
                                              type="date"
                                              value-format="yyyy-MM-dd"
                                              v-model="tonnageForm.openEndDate"
                                              style="width: 100%;"
                                              @mousedown="handleMouseEnter('openEndDate')"
                                              @mouseup="clearHighlight('openEndDate')"
                                              >
                              </el-date-picker>
                            </div>
                            </el-form-item>
                          </el-col>
                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.openarea')" required >
                          <el-col :span="11">
                            <el-form-item prop="openSeaareaName">
                              <el-autocomplete  v-model="tonnageForm.openSeaareaName"
                                                :placeholder="$t('message.addtonnage.openarea')"
                                                style="width: 100%;"
                                                :fetch-suggestions="querySearchOpenSea"
                                                :trigger-on-focus="true"
                                                @select="selectopensea"
                                                @change="changeopenSeaareaName"
                                                @mousedown="handleMouseEnter('openSeaareaName')"
                                              @mouseup="clearHighlight('openSeaareaName')"
                              >
                              </el-autocomplete>
                            </el-form-item>
                          </el-col>
                          <el-col class="line" :span="2">--</el-col>
                          <el-col :span="11">
                            <el-form-item  prop="openPortName">
                              <el-autocomplete v-model="tonnageForm.openPortName"
                                               :placeholder="$t('message.tonnage.openPort')"
                                               style="width: 100%;"
                                               :fetch-suggestions="querySearchOpenPort"
                                               :trigger-on-focus="true"
                                               @select="selectopenport"
                                               @change="changeopenport"
                                               @mousedown="handleMouseEnter('openPortName')"
                                              @mouseup="clearHighlight('openPortName')"
                              >
                              </el-autocomplete>
                            </el-form-item>
                          </el-col>
                        </el-form-item>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item :label="$t('message.addtonnage.sender')"
                                          prop="senderEmail">
                              <el-autocomplete v-model="tonnageForm.senderEmail"
                                               :placeholder="$t('message.addtonnage.sender')"
                                               style="width: 100%;"
                                               @mousedown="handleMouseEnter('senderEmail')"
                                              @mouseup="clearHighlight('senderEmail')"
                                               >
                              </el-autocomplete>
                            </el-form-item>
                          </el-col>
<!--                          <el-col :span="12">-->
<!--                            <el-form-item :label="$t('message.addtonnage.sendtime')"-->
<!--                                          prop="sendTime">-->
<!--                              <el-date-picker-->
<!--                                  value-format="yyyy-MM-dd"-->
<!--                                  :disabled-date="disabledDate"-->
<!--                                  type="datetime"-->
<!--                                  :placeholder="$t('message.addtonnage.sendtime')"-->
<!--                                  v-model="tonnageForm.sendTime"-->
<!--                                  style="width: 100%;">-->
<!--                              </el-date-picker>-->
<!--                            </el-form-item>-->
<!--                          </el-col>-->
                        </el-row>
                        <el-row>
                          <el-col :span="19">
                        <el-form-item :label="$t('message.addtonnage.memo')" prop="memo">
                          <el-input type="textarea" v-model="tonnageForm.memo" :placeholder="$t('message.addtonnage.please_memo')" :rows="3"></el-input>
                        </el-form-item>
                          </el-col>
                        </el-row>
                        <!--<el-checkbox-group v-model="tonnageForm.extra" prop="extra">-->
                        <!--</el-checkbox-group>-->
                        <!--:on-success="uploadSuccess"-->
                        <!--ref="upload"-->
                        <!--                          action="http://localhost:9528/api/tonnage/accessoryUpload"-->
                        <el-form-item id="btn-box2" size="medium" style="margin-top: 20px;">

                          <el-button style="margin-left: 103px"  type="primary" @click="addtonnage()" >{{$t('message.sidebar.issue')}}</el-button>

                        </el-form-item>
                        <Dialog @hidedialog="hidedialog" :dialogVisible="dialogVisible"></Dialog>
                      </el-form>
                        <el-form v-if="isEorD === '2'&& lang == 'en'&&!extract" ref="tonnageForm" label-width="100px" :model="tonnageForm" size="mini" >
                            <el-form-item :label="$t('message.tonnage.vesselName')">
                                <span>{{tonnageForm.shipVesselEname}}</span>
                            </el-form-item>
                          <el-form-item :label="$t('message.search.dwt')">
                            <span>{{tonnageForm.dwt}}{{$t('message.T')}}</span>
                          </el-form-item>
                          <el-form-item :label="$t('message.addtonnage.build')">
                            <span>{{tonnageForm.built}}{{$t('message.years')}}</span>
                          </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.opendate')">
                                <span>{{tonnageForm.openStartDate}}</span> ~ <span>{{tonnageForm.openEndDate}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.openarea')">
                                <span>{{tonnageForm.openSeaareaEname}}</span> - <span>{{tonnageForm.openPortEname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_country')">
                                <span>{{tonnageForm.intentCountryEname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_cargo')">
                                <span>{{tonnageForm.cargoEname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_area')">
                                <span>{{tonnageForm.intentSeaareaEname}}</span> - <span>{{tonnageForm.intentPortEname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.sender')">
                                <span>{{tonnageForm.senderEmail}}
                                   <el-button v-if="!this.tonnageForm.myFlag" id="sender2" style="margin-left:25%" @click="looksender">{{$t('message.check')}}</el-button>
                                </span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.sendtime')">
                                <span>{{tonnageForm.sendTime}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.files')" >
                              <div><file-list  :fileList="filesArray" @reload="reloaddelete" :myFlag="this.tonnageForm.myFlag" :type="this.type"></file-list></div>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.memo')">
                                <div>{{tonnageForm.memo}}</div>
                            </el-form-item>
                            <el-row>
                                <el-col :span="3"></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.pPT"  :checked="tonnageForm.pPT" disabled :label="$t('message.addtonnage.sszz')" name="PPT" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.rVTG" :checked="tonnageForm.rVTG" disabled :label="$t('message.addtonnage.shgz')" name="RVTG" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.fORTC" :checked="tonnageForm.fORTC" disabled :label="$t('message.addtonnage.tyzq')" name="FORTC" ></el-checkbox></el-col>
                            </el-row>

                        </el-form>
                        <el-form v-else-if="isEorD === '2'&& lang !== 'en'&&!extract" ref="tonnageForm" label-width="100px" :model="tonnageForm" size="mini" >
                            <el-form-item :label="$t('message.tonnage.vesselName')">
                                <span>{{tonnageForm.shipVesselCname}}</span> <span @click="checkshipDetail" style="color:cornflowerblue">查看详情</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.search.dwt')">
                                  <span>{{tonnageForm.dwt}}{{$t('message.T')}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.build')">
                              <span>{{tonnageForm.built}}{{$t('message.years')}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.opendate')">
                                <span>{{tonnageForm.openStartDate}}</span> ~ <span>{{tonnageForm.openEndDate}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.openarea')">
                                <span>{{tonnageForm.openSeaareaCname}}</span> - <span>{{tonnageForm.openPortCname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_country')">
                                <span>{{tonnageForm.intentCountryCname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_cargo')">
                                <span>{{tonnageForm.cargoCname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.intent_area')">
                                <span>{{tonnageForm.intentSeaareaCname}}</span> - <span>{{tonnageForm.intentPortCname}}</span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.sender')">
                                <span>{{tonnageForm.senderEmail}}
                                  <el-button v-if="!this.tonnageForm.myFlag" id="sender" style="margin-left:25%" @click="looksender">{{$t('message.check')}}</el-button>
                                </span>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.sendtime')">
                                <span>{{tonnageForm.sendTime}}</span>
                            </el-form-item>
                            <el-form-item  :label="$t('message.files')">
                              <div><file-list  :fileList="filesArray" @reload="reloaddelete" :myFlag="this.tonnageForm.myFlag" :type="this.type"></file-list></div>
                            </el-form-item>
                            <el-form-item :label="$t('message.addtonnage.memo')">
                                <div>{{tonnageForm.memo}}</div>
                            </el-form-item>
                            <el-row>
                                <el-col :span="3"></el-col>
                                <el-col :span="7">
                                    <el-checkbox  v-model="tonnageForm.pPT" :checked="tonnageForm.pPT" disabled :label="$t('message.addtonnage.sszz')" name="PPT" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.rVTG" :checked="tonnageForm.rVTG" disabled :label="$t('message.addtonnage.shgz')" name="RVTG" ></el-checkbox></el-col>
                                <el-col :span="7"><el-checkbox v-model="tonnageForm.fORTC" :checked="tonnageForm.fORTC" disabled :label="$t('message.addtonnage.tyzq')" name="FORTC" ></el-checkbox></el-col>
                            </el-row>
                        </el-form>
                      <el-form v-else-if="isEorD === '2'&& extract" ref="tonnageForm" label-width="100px" :model="tonnageForm" size="mini" >
                        <el-form-item :label="$t('message.tonnage.vesselName')">
                          <span>{{tonnageForm.shipVesselName}}</span>
                          &nbsp;&nbsp;&nbsp;
                           <span @click="checkshipDetail" style="color:cornflowerblue; cursor: pointer;">查看详情</span>
                        </el-form-item>
                        <el-form-item :label="$t('message.search.dwt')">
                          <span>{{tonnageForm.dwt}}{{$t('message.T')}}</span>
                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.build')">
                          <span>{{tonnageForm.built}}{{$t('message.years')}}</span>
                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.opendate')">
                          <span>{{tonnageForm.openStartDate}}</span> ~ <span>{{tonnageForm.openEndDate}}</span>
                        </el-form-item>
                        <el-form-item :label="$t('message.addtonnage.openarea')">
                          <span>{{tonnageForm.openSeaareaCname}}</span> - <span>{{tonnageForm.openPortCname}}</span>
                        </el-form-item>

                        <el-form-item :label="$t('message.addtonnage.sender')">
                                <span>{{tonnageForm.senderEmail}}
                                  <el-button v-if="!this.tonnageForm.myFlag" id="sender" style="margin-left:25%" @click="looksender">{{$t('message.check')}}</el-button>
                                </span>
                        </el-form-item>
<!--                        <el-form-item :label="$t('message.addtonnage.sendtime')">-->
<!--                          <span>{{tonnageForm.sendTime}}</span>-->
<!--                        </el-form-item>-->
                        <el-form-item :label="$t('message.addtonnage.memo')">
                          <div>{{tonnageForm.memo}}</div>
                        </el-form-item>
                      </el-form>
                    </div>
                </el-card>
            </div>
          <el-dialog
              :title="$t('message.email.modifyinformation')"
              v-model="deit_dialog"
              width="30%"
              :before-close="handleClose">
            <span>{{$t('message.email.sentence1')}}</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="edittonnage( )">{{$t('message.email.ok')}}</el-button>
                </span>
                </template>
            </el-dialog>
          <el-dialog
              :title="$t('message.email.matchinformation')"
              v-model="match_dialog"
              width="30%"
              :before-close="handleClose">
            <span>{{$t('message.email.sentence2')}}</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="match_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_match(tonnage_id)">{{$t('message.email.ok')}}</el-button>
                </span>
            </template>
          </el-dialog>
          <el-dialog
              :title="$t('message.email.deleteinformation')"
              v-model="del_dialog"
              width="30%"
              :before-close="handleClose">
            <span>{{$t('message.email.sentence3')}}</span>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_del(tonnage_id)">{{$t('message.email.ok')}}</el-button>
                </span>
            </template>
          </el-dialog>
            <div class="detail-right">
                <!--<div  id="map"></div>-->
<!--              <button>按钮</button>-->
                    <Mapbox @boxClick="checkshipDetail" @mapboxClick="handleModify" ref="mychild"  v-on:matchtonnageemail="matchtonnageemail" :shipId="tonnageForm.shipId"
                            :built="tonnageForm.built" :ename="tonnageForm.shipVesselEname" :extract="extract" :type="'tonnage'" :isEcord="isEorD" :email_detail="email_detail" str="tonnage" :highlightedText="highlightedText"  :fieldValuel="fieldValuel"></Mapbox>
<!--                    <Mapbox   ref="mychild" :temp='2' str="tonnage"></Mapbox>-->
               <!--                <div v-if="extract" class="extractstyle"></div>-->
            </div>
        </div>
<!--      手机-->
        <div v-if="isMobile" class="add-tonnage-mobile">
            <div class="detail-left">
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <span>船舶动态信息</span>
                            <div v-if="isEorD === '2'" class="collect">
                                <star-box :fontSize="starFontSize"></star-box>
                            </div>
                            
                        </div>
                    </template>
                    
                    <div class="card-left">
                        <el-form v-if="isEorD !== '2'" ref="tonnageForm" label-width="80px" :model="tonnageForm" size="mini" >
                            <el-form-item label="船舶名称">
                                <el-autocomplete v-model="vessel_name"
                                                 style="width: 75%;"
                                                 :fetch-suggestions="querySearchVessel"
                                                 :trigger-on-focus="false"
                                                 @select="selectvessel">
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item label="空船时间">
                                <el-col :span="11">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="tonnageForm.open_start_date" style="width: 100%;"></el-date-picker>
                                </el-col>
                                <el-col class="line" :span="1">-</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="选择日期" v-model="tonnageForm.open_end_date" style="width: 100%;"></el-date-picker>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="空船海域">
                                <el-col :span="11">
                                    <el-autocomplete v-model="open_seaarea"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchOpenSea"
                                                     :trigger-on-focus="true"
                                                     @select="selectopensea"
                                    >
                                    </el-autocomplete>
                                </el-col>
                                <el-col class="line" :span="1">-</el-col>
                                <el-col :span="11">
                                    <el-autocomplete v-model="open_port"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchOpenPort"
                                                     :trigger-on-focus="true"
                                                     @select="selectopenport">
                                    </el-autocomplete>
                                </el-col>
                            </el-form-item>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="意向国家">
                                        <el-autocomplete v-model="intentCountryCname"
                                                         style="width: 95%;"
                                                         :fetch-suggestions="querySearchIntentCountry"
                                                         :trigger-on-focus="false"
                                                         @select="selectintentcountry">
                                        </el-autocomplete>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="意向货物">
                                        <el-autocomplete v-model="cargo_name"
                                                         style="width: 90%;"
                                                         :fetch-suggestions="querySearchIntentCargo"
                                                         :trigger-on-focus="false"
                                                         @select="selectintentcargo"
                                                         @change="changeintentcargo"
                                        >
                                        </el-autocomplete>
                                        <el-button circle size="mini" icon="el-icon-plus" @click="cargonamedialog = true"></el-button>
                                    </el-form-item>
                                    <CDialog @hidecargodialog="hidecargodialog" :cargonamedialog="cargonamedialog"></CDialog>
                                </el-col>
                            </el-row>
                            <el-form-item label="意向海域">
                                <el-col :span="12">

                                    <el-autocomplete v-model="intent_area"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchIntentSea"
                                                     :trigger-on-focus="false"
                                                     @select="selectintentsea"

                                    >
                                    </el-autocomplete>
                                </el-col>
                                <el-col class="line" :span="1">-</el-col>
                                <el-col :span="11">
                                    <el-autocomplete v-model="intent_port"
                                                     style="width: 100%;"
                                                     :fetch-suggestions="querySearchIntentPort"
                                                     :trigger-on-focus="true"
                                                     @select="selectintentport">
                                    </el-autocomplete>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="发送人">
                                <el-autocomplete v-model="tonnageForm.sender" style="width: 75%;"></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="发送时间">
                                <el-date-picker type="date" placeholder="选择日期" v-model="tonnageForm.send_time" style="width: 75%;"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="其他要求">
                                <el-input type="textarea" v-model="tonnageForm.memo"></el-input>
                            </el-form-item>
                            <el-checkbox-group v-model="tonnageForm.extra">
                                <el-checkbox label="随时装载" name="PPT" ></el-checkbox>
                                <el-checkbox label="随后告知" name="RVTG" ></el-checkbox>
                                <el-checkbox label="同意租期" name="FORTC" ></el-checkbox>
                            </el-checkbox-group>
                            <el-form-item  size="medium">
                                <el-button v-if="isEorD === '1'"  type="primary" @click="onSubmit">修改信息</el-button>
                                <el-button v-else type="primary" @click="onSubmit">发布信息</el-button>
                                <el-button>重置</el-button>
                            </el-form-item>
                        </el-form>
                        <el-form v-if="isEorD === '2' " ref="tonnageForm" label-width="80px" :model="tonnageForm" size="mini" >
                            <el-form-item label="船舶名称">
                                <span>{{tonnageForm.vessel_name}}</span>
                            </el-form-item>
                            <el-form-item label="空船时间">
                                <span>{{tonnageForm.open_start_date}}</span> - <span>{{tonnageForm.open_end_date}}</span>
                            </el-form-item>
                            <el-form-item label="空船海域">
                                <span>{{tonnageForm.open_seaarea}}</span> - <span>{{tonnageForm.openPort}}</span>
                            </el-form-item>
                            <el-form-item label="意向国家">
                                <span>{{tonnageForm.intent_country}}</span>
                            </el-form-item>
                            <el-form-item label="意向货物">
                                <span>{{tonnageForm.cargo_name}}</span>
                            </el-form-item>
                            <el-form-item label="意向海域">
                                <span>{{tonnageForm.intent_seaarea}}</span> - <span>{{tonnageForm.intent_port}}</span>
                            </el-form-item>
                            <el-form-item label="发&nbsp;送&nbsp;人">
                                <span>{{tonnageForm.sender}}</span>

                            </el-form-item>
                            <el-form-item label="发送时间">
                                <span>{{tonnageForm.send_time}}</span>
                            </el-form-item>
                            <el-form-item label="其他要求">
                                <div>{{tonnageForm.memo}}</div>
                            </el-form-item>
                            <el-form-item>
                  <span class="detail-check" v-for="(item,index) of tonnageForm.extra" :key="index">
                    <i class="el-icon-circle-check"></i>
                    {{item}}
                  </span>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>

            </div>
            <div class="detail-right">
              <!--<div  id="map"></div>-->

            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters , mapState} from 'vuex'
  import StarBox from "../../components/star-box/StarBox.vue"
  import Dialog from "../../components/account_email/Extractemailcard"
  import Ship from '../../components/ship/Addship'
  import CDialog from "../../components/addcargoname/Addcargoname"
  import FileList from '../../components/filelist/FileList'
  import {getcargoname, getseaarea} from '../../api/associativeinput'

  import {
    addTonnage,
    searchTonnagebyId,
    /*jx add 24.03.20 新增接口：查看详情界面加载即显示海图*/
    searchAreabyName,
    searchPortbyName,
    updateTonnage,
    matchCargo,
    deleteTonnage,
    searchTonnagepayed,
    getFilelist, deletetonnageDocument
  } from "../../api/tonnage";
  import Mapbox from '../../components/mapbox/Mapbox'
  import { getMypoints} from "../../api/integral"
  import { ElMessageBox,  } from 'element-plus'
  import axios from 'axios';
  import { getToken } from "../../utils/auth";
  import {detailtonnage, extractTonnage,updateextracttonnage,searchemailbyid} from "../../api/emailextract";
  export default {
    inject: ['reload'],
    components: { StarBox, Dialog, CDialog, Mapbox,FileList ,Ship},
    data() {
      const validateOpenStart = (rule, value, callback) => {
        if (!value) {
          callback(new Error(window.vm.$t('message.date_please')))
        }  else if (this.tonnageForm.openEndDate !=='' &&this.tonnageForm.openStartDate > this.tonnageForm.openEndDate) {
          callback(new Error(window.vm.$t('message.startdatevalide')))
        } else {
          callback()
        }
      };
      const validateOpenEnd = (rule, value, callback) => {
        if (!value) {
          callback(new Error(window.vm.$t('message.date_please')))
        } else if (this.tonnageForm.openStartDate > this.tonnageForm.openEndDate) {
          callback(new Error(window.vm.$t('message.enddatevalide')))
        } else {
          callback()
        }
      };
      const validateSender = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(window.vm.$t('message.emailnull')));
        } else {
          if (value !== '') {
            var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
            if (!reg.test(value)) {
              callback(new Error(window.vm.$t('message.email.please_email')));
            }
          } else {
            callback();
          }
          callback();
        }
      };
      return {
        myVariable: false,
        fieldValuel:'',
        mergedHighlightedContent:'',
        highlightedText: '',
      isMouseOverInput: false,
      highlightedEmailContent: '',
      highlightedKeyword: "",  // 选中的关键字
      highlightedContent: "",  // 高亮后的内容
        type:'tonnage',
        drawer:false,
        draweroriginal:false,
        limitNum:2,
        headerObj: {
          Authorization: getToken(),
        },
        emailcontextForm:{
          email_content:''
        },
        files:{},
        fileList:[],
        filesArray:'',
        dialogVisibleShopImg: '',
        temp:'',
        currentPoint: '',
        disabled: '',//按钮权限
        buttonpermission: [],
        //发送时间只能选择当前时间之前的日期
        pickerBeginDateBefore: {
          disabledDate(date) {
            return date.getTime() > Date.now()
          }
        },
        map: {},
        deit_dialog: false,
        match_dialog: false,
        del_dialog: false,
        editdisabled: '',
        deletedisabled: '',
        matchdisabled: '',
        cargonamedialog: false,
        vessel_names: [],
        vessel_name: '',//对应v-model里的vessel_name
        open_seaarea: '',
        open_port: '',
        intentCountryId: '',
        cargo_name: '',
        intent_seaarea: '',
        intent_port: '',//v-model
        dialogVisible: false,
        languageCode: '',
        lang:'',
        isEorD: '',//当前是否是修改信息状态或者是详情页状态 规定 “1” 为修改 “2” 为详情
        tonnage_id: 0,//路由传入的修改页或详情页的tonnage的id
        starFontSize: '25px', //传给收藏组件，控制大小
        extract:'',
        email_detail:'',
        /*jx add 24.03.21 从船舶动态识别列表传过来的海域和港口名称，供海图显示用*/
        areaname:'',
        portname:'',
        extractForm:{
          code:'',
          built:'',
          dwt:'',
          memo:'',
          open_area:'',
          open_port:'',
          open_start_date:'',
          open_end_date:"",
          vessel_name:'',
          email:'',
          send_time:'',

        },
        tonnageForm: { // 航运动态表单对象
          dwt:'',
          built:'',
          openStartDate: '',
          openEndDate: '',
          senderEmail: '',
          sendTime: new Date(),
          memo: '',
          pPT: false,
          rVTG: false,
          fORTC: false,
          myFlag:'',
          shipVesselName: '',
          intentPortName: '',
          intentSeaareaName: '',
          openPortName: '',
          openSeaareaName: '',
          cargoName: '',
          intentCountryName: '',
          shipId: '',
          //shipId: '1049794599901593600',
          shipVesselEname: '',
          shipVesselCname: '',
          intentPortId: '',
          intentPortEname: '',
          intentPortCname: '',
          intentSeaareaId: '',
          intentSeaareaEname: '',
          intentSeaareaCname: '',
          openPortId: '',
          openPortEname: '',
          openPortCname: '',
          openSeaareaId: '',
          openSeaareaEname: '',
          openSeaareaCname: '',
          cargoNameId: '',
          cargoEname: '',
          cargoCname: '',
          intentCountryId: '',
          intentCountryEname: '',
          intentCountryCname: '',
          emailDetailId:''
        },
        pagination: {
          current: 0,
          pagesize: 4,
          total: 0
        },
        paiedForm: {
          consume: '',
          giftId: '',
          giftType: '',
          id: ''
        },
        rules: {
          shipVesselName: [{ message: window.vm.$t('message.shipvesselmessage'), required: true, trigger: ['blur','change']  }],
          openSeaareaName: [{ required: true, message: window.vm.$t('message.seaareamessage'),trigger: ['blur','change']  }],
          openPortName: [{ required: true, message: window.vm.$t('message.portmessage'), trigger: ['blur','change'] }],
          openStartDate: [{ type: 'date', validator: validateOpenStart, required: true, trigger: 'blur' }],
          openEndDate: [{ type: 'date', validator: validateOpenEnd, required: true, trigger: 'blur' }],
          senderEmail: [{ validator: validateSender, required: true, trigger: ['blur','change'] }],
          sendTime: [{ required: true, message: window.vm.$t('message.date_please'), trigger: 'blur' }],
          built:[{required: true, message: window.vm.$t('message.date_please'),trigger: ['blur','change']}],
        }
      }
    },

    computed:
     mapState({
      ...mapGetters(['isMobile', 'permission', 'accountemail', 'lang', 'portnamegroup', 'seanamegroup', 'vesselnamegroup', 'cargonamegroup', 'countrynamegroup']),
      vesselnames: (state) => state.metadata.vessel_name,

    }),
    // processedOpenEndDate() {
    //     // 如果 this.tonnageForm.openEndDate 是 '0-0-0'，则返回当前日期
    //     if (this.tonnageForm.openEndDate === '0-0-0') {
    //         return new Date().toISOString().substr(0, 10); // 返回 yyyy-MM-dd 格式的当前日期
    //     } else {
    //         return this.tonnageForm.openEndDate; // 否则返回原始值
    //     }
    // },
    // processedOpenStartDate() {
    //     if (this.tonnageForm.openStartDate === '0-0-0') {
    //         return new Date().toISOString().substr(0, 10); // 返回 yyyy-MM-dd 格式的当前日期
    //     } else {
    //         return this.tonnageForm.openStartDate; // 否则返回原始值
    //     }
    // },
    watch: {
      
      //监听路由变化，实现当前页面不刷新跳转路由
      '$route'(to, form) {
       // console.log(90900);
        this.reload()
        // this.tonnageForm = {
        //   vessel_name:'',
        //   open_start_date:'',
        //   open_end_date:'',
        //   open_seaarea:'',
        //   open_port:'',
        //   intent_country:'',
        //   cargo_name:'',
        //   intent_seaarea:'',
        //   intent_port:'',
        //   sender:'',
        //   send_time:'',
        //   memo:'',
        //   extra:[],
        // },
        //   this.init_addtonnage()
      }
    },
    
    methods: {
       handleModify() {
        //console.log(this.shipId)
      const shipVesselName = this.tonnageForm.shipVesselName;
      const dwt = this.tonnageForm.dwtdetail;
      const built = this.tonnageForm.built;
     // const openStartDate = this.tonnageForm.openStartDate;
     // const openEndDate= this.tonnageForm.opendatedetail;
      const opendatedetail=this.tonnageForm.opendatedetail;
      const openSeaareaName = this.tonnageForm.openSeaareaName;
      const openPortName = this.tonnageForm.openPortName;
      //const senderEmail = this.tonnageForm.senderEmail;
      // 执行一次获取并高亮的逻辑，将 cargoName 和 quantity 一起处理
       this.highlightAndPrintMergedContent(shipVesselName, dwt,built,opendatedetail,openSeaareaName,openPortName);
    },
    async highlightAndPrintMergedContent(fieldValue1, fieldValue2, fieldValue3,fieldValue4,fieldValue5,fieldValue6) {
  try {
    const res = await searchemailbyid(this.email_detail);
    if (res.data == null) {
      //this.$message.warning('获取原邮件内容失败');
    } else {
      this.emailcontent = res.data.content;
      this.text = this.emailcontent;
      
      // 执行高亮逻辑
      this.highlightedContent = this.highlightMatchingText(fieldValue1,fieldValue2,fieldValue3,fieldValue4,fieldValue5,fieldValue6);
      this.highlightedText=this.highlightedContent
      const mergedHighlightedContent = this.highlightMatchingText(fieldValue1,fieldValue2,fieldValue3,fieldValue4,fieldValue5,fieldValue6);
      console.log('Merged Highlighted Content:', mergedHighlightedContent);
      console.log(this.emailcontent)
    }
  } catch (error) {
    //console.error('获取原邮件内容失败', error);
   // this.$message.error('获取原邮件内容失败');
  }
},
   

highlightMatchingText(textToHighlight1, textToHighlight2,textToHighlight3,textToHighlight4,textToHighlight5,textToHighlight6) {
  // 在这里执行高亮逻辑
  const emailContent = this.emailcontent;  // 请替换为实际的邮件内容
//   const regex = /(\d+)k/g;
let highContent = emailContent;
// const updatedContent = emailContent.replace(regex, (match, group1) => {
//   const expandedNumber = group1 + "000";
//   return expandedNumber;
// });

  const escapedText1 = textToHighlight1.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText2 = textToHighlight2.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText3 = textToHighlight3.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  
  //const escapedText3 = textToHighlight3.replace(/[.*+?^${}()|[\]\\d, ]/g, '\\$&');
//.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
//.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
  const escapedText4 = textToHighlight4.replace(/[.*+?^${}()|[\]\\&]/g, '\\$&');
 // const escapedText4 = textToHighlight4.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText5 = textToHighlight5.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  const escapedText6 = textToHighlight6.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
  // const escapedText1 = textToHighlight1.replace(/[\.*+?^${}()|[\]\\s&]/g, '\\$&');
  // const escapedText2 = textToHighlight2.replace(/[\.*+?^${}()|[\]\\s&]/g, '\\$&');
  // const escapedText3 = textToHighlight3.replace(/[\.*+?^${}()|[\]\\s&]/g, '\\$&');
  // const escapedText4 = textToHighlight4.replace(/[\.*+?^${}()|[\]\\s&]/g, '\\$&');
  // const escapedText5 = textToHighlight5.replace(/[\.*+?^${}()|[\]\\s&]/g, '\\$&');
  // const regex1 = new RegExp(`\\b${escapedText1}\\b`, 'gi');
  // const regex2 = new RegExp(`\\b${escapedText2}\\b`, 'gi');
  // const regex3 = new RegExp(`\\b${escapedText3}\\b`, 'gi');
  // const regex4 = new RegExp(`\\b${escapedText4}\\b`, 'gi');
  // const regex5 = new RegExp(`\\b${escapedText5}\\b`, 'gi');
  // const regex6 = new RegExp(`\\b${escapedText6}\\b`, 'gi');
  const newRegex1 = new RegExp(escapedText1, 'gi');
  const newRegex2 = new RegExp(escapedText2, 'gi');
  const newRegex3 = new RegExp(escapedText3, 'gi');
  const newRegex4 = new RegExp(escapedText4, 'gi');
  const newRegex5 = new RegExp(escapedText5, 'gi');
  const newRegex6 = new RegExp(escapedText6, 'gi');
let regex1 = newRegex1;
let regex2 = newRegex2;
let regex3 = newRegex3;
let regex4 = newRegex4;
let regex5 = newRegex5;
let regex6 = newRegex6;
  // const regex1 = new RegExp(escapedText1, 'gi');
  // const regex2 = new RegExp(escapedText2, 'gi');
  // const regex3 = new RegExp(escapedText3, 'gi');
  // const regex4 = new RegExp(escapedText4, 'gi');
  // const regex5 = new RegExp(escapedText5, 'gi');
  // const regex6 = new RegExp(escapedText6, 'gi');

  if (newRegex1.toString() === "/(?:)/gi") {
  regex1 = null;
}
if (newRegex2.toString() === "/(?:)/gi") {
  regex2 = null;
}
if (newRegex3.toString() === "/(?:)/gi") {
  regex3 = null;
}
if (newRegex4.toString() === "/(?:)/gi") {
  regex4 = null;
}
if (newRegex5.toString() === "/(?:)/gi") {
  regex5 = null;
}
if (newRegex6.toString() === "/(?:)/gi") {
  regex6 = null;
}

  console.log('观察:',regex1);
  console.log('观察:',regex2);
  console.log('观察:',regex3);
  console.log('观察:',regex4);
  console.log('观察:',regex5);
  console.log('观察:',regex6);

  // 将两个关键信息同时高亮
  if (regex1) {
  highContent = highContent.replace(regex1, (match) => `<span class="highlight-pink">${match}</span>`);
}
if (regex2) {
  highContent = highContent.replace(regex2, (match) => `<span class="highlight-blue">${match}</span>`);
}
if (regex3) {
  highContent = highContent.replace(regex3, (match) => `<span class="highlight-red">${match}</span>`);
}
if (regex4) {
  highContent = highContent.replace(regex4, (match) => `<span class="highlight-green">${match}</span>`);
}
if (regex5) {
  highContent = highContent.replace(regex5, (match) => `<span class="highlight-orange">${match}</span>`);
}
if (regex6) {
  highContent = highContent.replace(regex6, (match) => `<span class="highlight-purple">${match}</span>`);
}
  // const highlightedContent = emailContent
  // .replace(regex1, (match) => `<span class="highlight-pink">${match}</span>`)
  // .replace(regex2, (match) => `<span class="highlight-blue">${match}</span>`)
  // .replace(regex3, (match) => `<span class="highlight-red">${match}</span>`)
  // .replace(regex4, (match) => `<span class="highlight-green">${match}</span>`)
  // .replace(regex5, (match) => `<span class="highlight-orange">${match}</span>`)
  // .replace(regex6, (match) => `<span class="highlight-purple">${match}</span>`);

 


/* 其他颜色的类，根据您的需求添加 */

  // const highlightedContent = emailContent.replace(regex1, (match) => `<span style="color: black; font-weight: bold; background-color: #FFC0CB;">${match}</span>`)
  //                                            .replace(regex2, (match) => `<span style="color: black; font-weight: bold; background-color: #007BFF;">${match}</span>`)
  //                                            .replace(regex3, (match) => `<span style="color: black; font-weight: bold; background-color: red;">${match}</span>`)
  //                                            .replace(regex4, (match) => `<span style="color: black; font-weight: bold; background-color: #98FB98;">${match}</span>`)
  //                                            .replace(regex5, (match) => `<span style="color: black; font-weight: bold; background-color: orange;">${match}</span>`)
  //                                            .replace(regex6, (match) => `<span style="color: black; font-weight: bold; background-color: #c7aef8;">${match}</span>`);


  // 返回高亮后的内容
  this.highlightedText = highContent;
 return highContent;
},
// highlightMatchingText(textToHighlight1, color1, textToHighlight2, color2,textToHighlight3, color3,textToHighlight4, color4,textToHighlight5, color5,textToHighlight6, color6) {   

//   // 在这里执行高亮逻辑
//   const emailContent = this.emailcontent;  // 请替换为实际的邮件内容

//   // 将传入的文本存储为数组
//   const texts = [textToHighlight1, textToHighlight2, textToHighlight3, textToHighlight4, textToHighlight5, textToHighlight6];

//   // 将颜色值存储为数组
//   const colors = ['#FFC0CB', '#007BFF', 'red', '#98FB98', 'orange', '#c7aef8'];

//   // 遍历文本数组，对非空值进行高亮处理
//   texts.forEach((text, index) => {
//     if (text !== null && text !== undefined && text !== '') {
//       const escapedText = text.replace(/[.*+?^${}()|[\]\\d]/g, '\\$&');
//       const regex = new RegExp(escapedText, 'gi');
//       // 将匹配到的文本进行高亮
//       const highlightedContent = emailContent.replace(regex, (match) => `<span style="background-color: ${colors[index]}">${match}</span>`);
//     }
//   });

//   // 返回高亮后的内容
//   return highlightedContent;
// },


    removeNonBreakingSpaces(text) {
      // 使用正则表达式将&nbsp;替换为空字符串
      return text.replace(/&nbsp;/g, '');
    },



      handleMouseEnter(fieldName) {
      this.isMouseOverInput = true;
      const fieldValues = {
    shipVesselName: this.tonnageForm.shipVesselName,
    dwt: this.tonnageForm.dwtdetail,
    built:this.tonnageForm.built,
    openStartDate:this.tonnageForm.opendatedetail,
    openEndDate:this.tonnageForm.opendatedetail,
    openSeaareaName:this.tonnageForm.openSeaareaName,
    openPortName : this.tonnageForm.openPortName,
    senderEmail : this.tonnageForm.senderEmail,
    // 添加其他字段及其对应的值
  };

  // 根据 fieldName 查找映射表中的对应值
  const fieldValue = fieldValues[fieldName];
     // const fieldValue = this.tonnageForm[fieldName];

     // this.fieldValuel = fieldValue;
    console.log(`Mouse entered ${fieldName}, field value: ${fieldValue}`);
    this.fetchAndHighlightEmailContent(fieldValue);
    // 将文本滚动到中间
  //this.scrollTextIntoView(fieldValue);
   // this.scrollIntoView(fieldValue); // 添加此行代码

  
    //const element = document.getElementById('highlightedKeyword');
   // console.log(element);
      // if (fieldValue) {
      //   console.log(1);
      //   fieldValue.scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'center',
      //     inline: 'center'
      //   });}
      //this.highlightEmailContent(fieldValue);
      
      
    },
    
   


  
    // scrollIntoView(fieldValue) {
    // // 获取包含邮件内容的元素
    // let emailContentElement = document.getElementById('yourEmailContentElementId');

    // // 查找包含搜索词的文本节点
    // let textNode = this.findTextNodeWithSearchTerm(emailContentElement, fieldValue);

    // if (textNode) {
    //   // 将包含搜索词的文本节点滚动到屏幕中间
    //   textNode.parentElement.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //     inline: 'center'
    //   });
    // }},
    async fetchAndHighlightEmailContent(fieldValue) {
  try {
    const res = await searchemailbyid(this.email_detail);
    if (res.data == null) {
     // this.$message.warning('获取原邮件内容失败');
    } else {
      this.emailcontent = res.data.content;
      this.text = this.emailcontent;
      // 执行高亮逻辑
      this.highlightEmailContent(fieldValue);
     
    }
  } catch (error) {
    console.error('获取原邮件内容失败', error);
   // this.$message.error('获取原邮件内容失败');
  }
},

    highlightEmailContent(fieldValue) {
    // 高亮显示匹配的文本
    searchemailbyid(this.email_detail).then((res)=>{
             if(res.data==null) 
             this.$message.warning('获取原邮件内容失败')
             else  
            {
            this.emailcontent= res.data.content;
            this.text = this.emailcontent;
            }
           })
   
    if (this.isMouseOverInput) {
      
    // const escapedFieldValue = fieldValue.replace(/[.*+?^${}()|[\]\\d]&/g, '\\$&');
     const escapedFieldValue = fieldValue.replace(/[.*+?^${}()|[\]\\&]/g, '\\$&');
        const regex = new RegExp(escapedFieldValue, 'gi');
        this.highlightedEmailContent = this.highlightedContent.replace(regex, (match) => `<span class="highlight">${match}</span>`);
        this.highlightedText = this.highlightedEmailContent;
//         let articleShow=document.getElementsByClassName("detail-right")[0];
//         let aAns=document.getElementsByClassName("highlight");
//         if(aAns.length>0){
//           console.log(aAns);
//           // 获取第一个高亮元素
//   let firstHighlightedElement = aAns[0];

// // 设置滚动位置为高亮元素的顶部位置减去一些偏移
// articleShow.scrollTop = firstHighlightedElement.offsetTop - 10;
//          // articleShow.scrollTop=aAns.offsetTop-10;
//         }
       // this.highlightedEmailContent = this.highlightedContent.replace(regex, (match) => `<span id="highlightedKeyword" class="highlight">${match}</span>`);
        //this.highlightedText = this.highlightedEmailContent;
      }
    //const escapedFieldValue = fieldValue.replace(/[.*+?^${}()|[\]\\]&/g, '\\$&');
   // const regex = new RegExp(escapedFieldValue, 'g');
   // this.highlightedEmailContent = this.emailcontent.replace(regex, (match) => `<span class="highlight">${match}</span>`);
    //this.highlightedText = this.emailcontent.replace(regex, (match) => `<span class="highlight">${match}</span>`)
 
    
    //this.highlightedText = this.emailcontent;
    //"aMsg", '来自A页面的消息' 
    //EventBus.$emit('highlightedEmailContent', this.highlightedEmailContent);
    //const regex = new RegExp(fieldValue, 'g');
    //this.highlightedEmailContent = this.emailcontent.replace(regex, (match) => `<span class="highlight">${match}</span>`);
    //this.$eventBus.$emit('highlightEmailContent', this.cargoForm.cargoName);
   // console.log(this.highlightedEmailContent);
   
    },

    clearHighlight(fieldName) {
      this.isMouseOverInput = false;
      //this.highlightedText = this.emailcontent;
      // logic to clear highlight when mouse leaves the field
      //console.log(`Mouse left ${fieldName}`);
      
    },

      guanbi(data){
        this.drawer=false
        this.tonnageForm.shipVesselName = data.vesselEname
        this.tonnageForm.shipVesselEname = data.vesselEname
        this.tonnageForm.shipVesselCname = data.vesselEname
        this.tonnageForm.dwt = data.dwt
        this.tonnageForm.built = data.built
        this.changevesellname()
      },
      matchtonnageemail(data){
        this.tonnageForm=data
        this.tonnageForm.senderEmail =this.tonnageForm.senderEmail== undefined ? localStorage.getItem('accountemail'): this.tonnageForm.senderEmail
        this.tonnageForm.sendTime = new Date()
      },
      changecargoname(cname,ename){
        if(this.lang=='en'){
          this.tonnageForm.cargoName=ename;
        }else{
          this.tonnageForm.cargoName=cname;
        }
        this.tonnageForm.cargoEname = ename;
        this.tonnageForm.cargoCname = cname;
        getcargoname(this.tonnageForm).then((res)=>{
          if(res.error_code==200){
            this.tonnageForm.cargoNameId=res.datas[0].id
          }
        })
      },
      // 上传前
      handleChange(file,fileList){
        this.fileList = fileList
         const isLt1M = file.size < 2048000;
        if (!isLt1M) {
          this.$message({
            message: window.vm.$t('message.file.file_size'),
            type: 'warning'
          });
          this.$refs.upload.handleRemove(file)
        }
        return isLt1M;
        },
      // 限制文件数量
    exceedFile(files, fileList) {
      //console.log('只能选择2个文件');
      if(this.lang=='en'){
        this.$notify.warning({
          title: 'warning',
          message: `only can select ${this.limitNum} files，but select  ${files.length + fileList.length} files in total`
        });
      }else{
        this.$notify.warning({
          title: '警告',
          message: `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
        });
      }
    },
      loadError(res){
        this.$message({
          message:res.msg+"Sorry，上传失败了",
          type: 'warning'
        });
      },
      handlePreview(file) {
       },
      handleMove(files, fileList){
         this.fileList=fileList
      },
      beforeRemove(files,fileList){
        let ids = [files.id]
        if(files.id != null){
          deletetonnageDocument(ids).then( res => {
            if(res.error_code == 200){
              this.$message.success(window.vm.$t('message.deletesucc'))
              this.del_dialog = false
              this.$emit('reload');
            }
          })
        }
      },
      handleRemove(file){
       },
      disabledDate(date) {
        return date.getTime() > Date.now()
      },
      // //从路由里获得id，根据id获得船舶动态信息的详细信息
      init_addtonnage() {
        this.tonnage_id = this.$route.params.id
        this.isEorD = this.$route.params.eord
        this.extract=this.$route.query.extract
        this.email_detail=this.$route.query.email_detail
        if (this.isEorD == 2 || this.isEorD == 1) {
           //获取文件列表
          //后端接受id为long类型
          let params = new URLSearchParams()
          params.append('id', this.tonnage_id)
          getFilelist(params).then(res => {
            this.filesArray = res.data
           // console.log(res.data)
            var editFileList = res.data.map((item) => {
              const data = {
                name: item.fileName,
                id: item.id,
              }
              return data;
            })
            this.fileList = editFileList
          })
          if (!this.extract) {
          searchTonnagebyId(this.tonnage_id).then(async response => {
            this.tonnageForm = response.data
            if (this.lang == 'en') {
              this.tonnageForm.shipVesselName = this.tonnageForm.shipVesselEname
              this.tonnageForm.openSeaareaName = this.tonnageForm.openSeaareaEname
              this.tonnageForm.openPortName = this.tonnageForm.openPortEname
              this.tonnageForm.intentCountryName = this.tonnageForm.intentCountryEname
              this.tonnageForm.intentSeaareaName = this.tonnageForm.intentSeaareaEname
              this.tonnageForm.intentPortName = this.tonnageForm.intentPortEname
              this.tonnageForm.cargoName = this.tonnageForm.cargoEname
            } else {
              this.tonnageForm.shipVesselName = this.tonnageForm.shipVesselCname
              this.tonnageForm.openSeaareaName = this.tonnageForm.openSeaareaCname
              this.tonnageForm.openPortName = this.tonnageForm.openPortCname
              this.tonnageForm.intentCountryName = this.tonnageForm.intentCountryCname
              this.tonnageForm.intentSeaareaName = this.tonnageForm.intentSeaareaCname
              this.tonnageForm.intentPortName = this.tonnageForm.intentPortCname
              this.tonnageForm.cargoName = this.tonnageForm.cargoCname
            }
            this.tonnageForm.openSeaareaId = response.data.openSeaareaId
            this.tonnageForm.openPortId = response.data.openPortId
            // this.$refs.mychild.initMap()
            await this.$refs.mychild.sealayer(this.tonnageForm.openSeaareaId, 'tonnage');
            await this.$refs.mychild.portimage(this.tonnageForm.openPortId, 'tonnage');

          })
        }
        else
        {
          // jx add 24.03.20 新增需求：修改页面加载时即显示地图
          if(this.portname == null | this.areaname == null)
          {
            //console.log("zhenfan a!")
            this.portname = this.$route.query.DetailPortName
            this.areaname = this.$route.query.DetailAreaName
          }
          if(this.areaname != '')
          {
            searchAreabyName(this.areaname).then(async Arearesponse => 
            {
              if(Arearesponse.data == null)
              {
                this.$message.warning('没有相应的海域，可能识别时发生错误');
                
              }
              else
              {
                await this.$refs.mychild.sealayer(Arearesponse.data.openSeaareaId, 'tonnage');
              }
              
            })
          }
          searchPortbyName(this.portname).then(async Portresponse =>
          {
            //console.log(this.portname)
            //console.log("jxjxjx测试portcode")
            //console.log(Portresponse)
            await this.$refs.mychild.portimage(Portresponse, 'tonnage');
            if(Portresponse == '港口不存在')
            {
              this.$message.warning('没有相应的港口，可能识别时发生错误');
            }
          })
          /******************/
          detailtonnage(this.tonnage_id).then((res)=>
          {
            //console.log(res)
            this.tonnageForm.myFlag = true
            this.tonnageForm.openEndDate = res.data.open_end_date
            this.tonnageForm.openStartDate = res.data.open_start_date
            console.log(this.tonnageForm.openEndDate)
            if (this.tonnageForm.openStartDate === '0-0-0') {
        // 如果值为 '0-0-0'，将其转换为当前日期
        this.tonnageForm.openStartDate= null;
      } 
      if (this.tonnageForm.openEndDate === '0-0-0') {
        // 如果值为 '0-0-0'，将其转换为当前日期
        this.tonnageForm.openEndDate= null;
      } 
            // this.tonnageForm.openStartDate = handleInvalidDate(res.data.open_start_date);
            // this.tonnageForm.openEndDate = handleInvalidDate(res.data.open_end_date);
            this.tonnageForm.openSeaareaCname = res.data.open_area
            this.tonnageForm.openSeaareaEname = res.data.open_area
            this.tonnageForm.openSeaareaName = res.data.open_area
            this.tonnageForm.openPortCname = res.data.open_port
            this.tonnageForm.openPortEname = res.data.open_port
            this.tonnageForm.openPortName = res.data.open_port
            this.tonnageForm.senderEmail = res.data.email
            this.extractForm.code = res.data.code
            this.tonnageForm.built=res.data.built
            this.tonnageForm.dwt=res.data.dwt
            this.tonnageForm.dwtdetail=res.data.dwtdetail
            this.tonnageForm.mvdetail=res.data.mvdetail
            this.tonnageForm.opendatedetail=res.data.opendatedetail
          //  this.tonnageForm.sendTime=res.data.send_time
            this.tonnageForm.shipVesselCname =res.data.vessel_name
            this.tonnageForm.shipVesselEname =res.data.vessel_name
            this.tonnageForm.shipVesselName =res.data.vessel_name
            this.tonnageForm.memo =res.data.memo
            // this.tonnageForm.shipId=this.tonnage_id
              })
              
        }
        }
      },
      saveextract(){
        this.extractForm.open_end_date= this.tonnageForm.openEndDate
        this.extractForm.open_start_date= this.tonnageForm.openStartDate
        this.extractForm.open_area= this.tonnageForm.openSeaareaName
        this.extractForm.open_port= this.tonnageForm.openPortName
        this.extractForm.email= this.tonnageForm.senderEmail
        this.extractForm.memo= this.tonnageForm.memo
        this.extractForm.dwt= this.tonnageForm.dwt
        // 假设 tonnageForm.built 是一个日期字符串，比如 "2023-12-31T16:00:00.000Z"
const originalDate = new Date(this.tonnageForm.built);
this.extractForm.built = originalDate.getFullYear().toString(); // 获取并设置年份信息

// 可选：如果需要在前端显示完整的日期格式，可以参考下面的代码
//this.extractForm.built = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;

        // this.extractForm.built= this.tonnageForm.built
        // const originalDate = new Date(this.tonnageForm.built);
        // this.extractForm.built = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
        // const date = new Date(this.tonnageForm.built);
        // date.setDate(date.getDate() + 1); // 加一天
        // this.extractForm.built = date.toISOString();
        this.extractForm.vessel_name= this.tonnageForm.shipVesselName
      //  this.extractForm.send_time= this.tonnageForm.sendTime
        updateextracttonnage(this.tonnage_id,this.extractForm).then(res=>{
          if(res.error_code==200){
            this.$message.success(window.vm.$t('message.savesucc'))
            // this.$router.push({path:`/tonnage/addtonnage/2/${this.tonnage_id}`,
            //   query:{
            //     extract:true,
            //     email_detail:this.email_detail
            //   }}
            // )
          }
        }).then(()=>{
          this.$router.push('/extract/emaillist/emailtonnage')
        })
      },
      open_Match(matchObj) {
        this.matchObj = matchObj
        this.match_dialog = true
      },
      open_Edit(editObj) {
        this.editObj = editObj
        this.deit_dialog = true
      },
      open_Del(delObj) {
        this.delObj = delObj
        this.del_dialog = true
      },
      go_edit(id) {
        this.deit_dialog = false;
        this.$router.push(`/tonnage/addtonnage/${1}/${id}`)
        
      },
      openemailextract(){
        this.$refs.mychild.tabtick()
      },
      checkshipDetail(){
        this.$refs.mychild.checkship()
      },
      gotolist(){
        this.$router.push('/extract/emaillist/emailtonnage')
      },
       go_editextract(id){
       // this.fieldValuel = "true";
       // console.log(this.fieldValuel);
       // await this.handleModify();  // 使用 await 等待 handleModify 执行完成
        //this.handleModify();
       // console.log(123);
      // this.$refs.button2.$el.click();
        this.$router.push({path:`/tonnage/addtonnage/${1}/${id}`,
         query:{
          extract:true,
          email_detail:this.email_detail,
          DetailAreaName : this.areaname,
          DetailPortName : this.portname,
         }
        })
      
  
      },
      go_match() {
        if (this.lang == 'en') {
          this.languageCode = 2
        } else {
          this.languageCode = 1
        }
        const param = {
          query: {
            tonnageId: this.tonnage_id,
            languageCode: this.languageCode,
          },
          pagination: this.pagination
        }
        matchCargo(param).then(response => {
          if (response.error_code == 200 && response.datas.length != 0) {
            this.match_dialog = false
            this.matchObj = response.datas[0].tonnageCardModel;
            this.$store.commit("metadata/SET_Tonnageinfo", this.matchObj);
            this.$router.push(`/tonnage/tonmatching/${this.tonnage_id}`)
          } else {
            this.match_dialog = false
            alert(window.vm.$t('message.nofoundmatch'))
          }
        })

      },
      go_del() {
        this.del_dialog = false;
        var deleteId = [this.tonnage_id]
        deleteTonnage(deleteId).then(response => {
          if (response.error_code == 200) {
            this.del_dialog = false
            this.$router.push('/tonnage/search_tonnage')
            this.$message.success(window.vm.$t('message.tonnage.deletedinfo'))
          }
        })
      },
      addtonnage: _.debounce(function() {
          this.saveextract()
          this.$refs.tonnageForm.validate((valid) => {
              if (valid) {
                  if(this.tonnageForm.built.length!=4){
                    this.tonnageForm.built = (this.tonnageForm.built).getFullYear().toString()
                  }
                  if(typeof (this.tonnageForm.openStartDate) == "object"){
                    this.tonnageForm.openStartDate=(this.tonnageForm.openStartDate).getTime()
                  }
                  else if(this.tonnageForm.openStartDate.includes("/")){
                    this.tonnageForm.openStartDate= this.tonnageForm.openStartDate.replaceAll('/','-')+'T16:00:00.000Z'
                  }
                  if(typeof (this.tonnageForm.openEndDate) == "object"){
                    this.tonnageForm.openEndDate=(this.tonnageForm.openEndDate).getTime()
                  }else if(this.tonnageForm.openEndDate.includes("/")){
                    this.tonnageForm.openEndDate= this.tonnageForm.openEndDate.replaceAll('/','-')+'T16:00:00.000Z'
                  }

                addTonnage(this.tonnageForm).then(res => {
                  if (res.error_code == 200 && res.data != "") {
                      this.$message.success(window.vm.$t('message.tonnage.releaseinfo'))
                    //发布成功并返回id之后上传文件
                    if (this.fileList.length ==0 ) {
                      this.$router.push('/extract/emaillist/emailtonnage')
                      //this.$router.push('/tonnage/search_tonnage')
                    }else {
                      const formData = new FormData()
                      this.fileList.forEach(
                          (val, index) => {
                            formData.append("files", val.raw);
                          }
                      );
                      formData.append("id", res.data);
                      axios({
                        method: 'post',
                        url: '/api/tonnage/accessory/listUpload',
                        data: formData,
                        headers: {
                          Authorization: getToken()
                        }
                      }).then(res => {
                        this.dialogVisibleShopImg = false
                        if (res.data.error_code == 200){
                            this.$message({
                              message: window.vm.$t('message.file.filesucc'),
                              type: "success"
                            });
                          this.$router.push('/tonnage/search_tonnage')
                        } else {
                            this.$message({
                              message: window.vm.$t('message.file.filefail'),
                              type: "error"
                            });
                        }
                      })
                    }
                  }
                })
              }else{
                  this.$alert(window.vm.$t('message.checkandrefill'), window.vm.$t('message.Incorrect'), {
                    confirmButtonText: window.vm.$t('message.OK'),
                  });
              }
            })
          }, 2000, {
            'leading': true, //在延迟开始前立即调用事件
            'trailing': false, //在延时结束后不调用,保证事件只被触发一次
          }
      ),
      hidedialog() {
        this.dialogVisible = false
      },
      hidecargodialog() {
        this.cargonamedialog = false
      },
      //openedit
      openedit(){
        this.deit_dialog = true
        
      },
      //修改船舶动态信息
      edittonnage: _.debounce(function() {
       // this.handleModify();
       // console.log(123);
        this.deit_dialog = false
          this.$refs.tonnageForm.validate((valid) => {
            if (valid) {
              var str =this.tonnageForm.built
              if(str.length!==4){
                this.tonnageForm.built = (this.tonnageForm.built).getFullYear().toString()
              }
             // this.tonnageForm.built = (this.tonnageForm.built).getFullYear().toString()
              if(typeof (this.tonnageForm.openStartDate) == "object"){
                this.tonnageForm.openStartDate=(this.tonnageForm.openStartDate).getTime()
              }
              if(typeof (this.tonnageForm.openEndDate) == "object"){
                this.tonnageForm.openEndDate=(this.tonnageForm.openEndDate).getTime()
              }
              // this.tonnageForm.openStartDate= new Date(this.tonnageForm.openStartDate).getTime()
              // this.tonnageForm.openEndDate= new Date(this.tonnageForm.openEndDate).getTime()
              // this.tonnageForm.sendTime= new Date(this.tonnageForm.sendTime).getTime()
              updateTonnage(this.tonnageForm).then(res => {
                if (res.error_code == 200 && res.data != "") {
                    this.$message.success( window.vm.$t('message.tonnage.modifiedinfo'))
                  //发布成功并返回id之后上传文件
                  if(this.fileList.length==0){
                    this.$router.push('/tonnage/search_tonnage')
                  }else{
                    const formData = new FormData()
                    this.fileList.forEach(
                        (val, index) => {
                          formData.append("files", val.raw);
                        }
                    );
                    formData.append("id", res.data);
                    axios({
                      method: 'post',
                      url: '/api/tonnage/accessory/listUpload',
                      data: formData,
                      headers: {
                        Authorization: getToken()
                      }
                    }).then(res => {
                       this.dialogVisibleShopImg = false
                      if (res.data.error_code == 200) {
                          this.$message({
                            message: window.vm.$t('message.file.filesucc'),
                            type: "success"
                          });
                        this.$router.push('/tonnage/search_tonnage')
                      } else {
                          this.$message({
                            message: window.vm.$t('message.file.filefail'),
                            type: "error"
                          });
                      }
                    })
                  }
                }
              })
            }else{
              this.$alert(window.vm.$t('message.checkandrefill'), window.vm.$t('message.Incorrect'), {
                confirmButtonText: window.vm.$t('message.OK'),
              });
            }
          })
        }, 2000, {
          'leading': true, //在延迟开始前立即调用事件
          'trailing': false, //在延时结束后不调用,保证事件只被触发一次
        }
      ),
      async looksender() {
        this.paiedForm.id = this.tonnage_id
        this.paiedForm.consume = false
        this.paiedForm.giftType = ''
         if (this.temp == 'true') {
            ElMessageBox.confirm(
                window.vm.$t('message.buycheck'),
                {
                  confirmButtonText: window.vm.$t('message.OK'),
                  cancelButtonText: window.vm.$t('message.cancel'),
                  type: 'info',
                }
            ).then(() => {
              //  this.paiedForm.consume = true  积分够，点击确认购买，将consume改为true
              searchTonnagepayed(this.paiedForm).then(response => {
                if (response.error_code == 200) {
                  this.tonnageForm.senderEmail = response.data.senderEmail
                }
              })
            })
        } else {
          await getMypoints().then(response => {
            this.currentPoint = response.data
          })
          if (this.currentPoint >= 1) {
              ElMessageBox.confirm(
                  window.vm.$t('message.isbuy'),
                  window.vm.$t('message.Tips'),
                  {
                    confirmButtonText: window.vm.$t('message.OK'),
                    cancelButtonText: window.vm.$t('message.cancel'),
                    type: 'info',
                  }
              ).then(() => {
                this.paiedForm.consume = true  //积分够，点击确认购买，将consume改为true
                searchTonnagepayed(this.paiedForm).then(response => {
                  if (response.error_code == 200) {
                    this.tonnageForm.senderEmail = response.data.senderEmail
                  }
                })
              })
          } else {
            //积分不足，前去充值
              ElMessageBox.confirm(
                  window.vm.$t('message.gotobuy'),
                  window.vm.$t('message.Tips'),
                  {
                    confirmButtonText: window.vm.$t('message.OK'),
                    cancelButtonText: window.vm.$t('message.cancel'),
                    type: 'info',
                  }
              ).then(() => {
                this.$router.push("/person/personalmessage/deposit")
              })

          }
        }
      },
      drawerori(){
        this.draweroriginal=true
        searchemailbyid(this.email_detail).then((res)=>{
          if(res.data==null) this.$message.warning('获取原邮件内容失败')
          else  this.emailcontextForm.email_content= res.data.content
        })
      },
      extractemail(){
        extractTonnage(this.emailcontextForm).then(response=>{
          this.tonnageForm.dwt = response.data.tonnages[0].dwt
          this.tonnageForm.openSeaareaName = response.data.tonnages[0].open_area
          this.tonnageForm.openSeaareaCname = response.data.tonnages[0].open_area
          this.tonnageForm.openPortName = response.data.tonnages[0].open_port
          this.tonnageForm.openPortCname = response.data.tonnages[0].open_port
          this.tonnageForm.openStartDate = response.data.tonnages[0].open_start_date
          this.tonnageForm.openEndDate = response.data.tonnages[0].open_end_date
          this.tonnageForm.built = response.data.tonnages[0].built+''
          this.tonnageForm.shipVesselEname = response.data.tonnages[0].vessel_name
          this.tonnageForm.shipVesselCname = response.data.tonnages[0].vessel_name
          this.tonnageForm.shipVesselName = response.data.tonnages[0].vessel_name
        })
      },

      //船名联想输入的查询
      querySearchVessel(queryString, callback) {
        this.$store.commit('metadata/querySearchVessel', queryString)
        callback(this.vesselnamegroup)
      },
      //开放海域联想输入
      querySearchOpenSea(queryString, callback) {
        this.$store.commit('metadata/querySearchSea', queryString)
        callback(this.seanamegroup)
      },
      //开放港口联想输入
      querySearchOpenPort(queryString, callback) {
        this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tonnageForm.openSeaareaId})
        callback(this.portnamegroup)
      },
      //意向海域联想输入
      querySearchIntentSea(queryString, callback) {
        this.$store.commit('metadata/querySearchSea', queryString)
        callback(this.seanamegroup)
      },
      //意向港口联想输入
      querySearchIntentPort(queryString, callback) {
        this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.tonnageForm.intentSeaareaId})
        callback(this.portnamegroup)
      },
      //货名联想输入
      querySearchIntentCargo(queryString, callback) {
        this.$store.commit('metadata/querySearchCargo', queryString)
        callback(this.cargonamegroup)
      },
      //国家联想输入
      querySearchIntentCountry(queryString, callback) {
        this.$store.commit('metadata/querySearchCountry', queryString)
        callback(this.countrynamegroup)
      },

      //联想输入select
      selectvessel(item) {
        if(item.id == '-1'){
          this.tonnageForm.shipVesselName = ''
          return
        }
        this.tonnageForm.shipId = item.id
        this.tonnageForm.dwt = item.dwt
        this.tonnageForm.built = item.built
        if (this.lang == 'en') {
          this.tonnageForm.shipVesselCname = item.cname
          this.tonnageForm.shipVesselEname = item.value
        } else {
          this.tonnageForm.shipVesselCname = item.value
          this.tonnageForm.shipVesselEname = item.ename
        }
      },
      selectopensea(item) {
       
        if(item.id == '-1'){
          this.tonnageForm.openSeaareaName = ''
          return
        }
        if (this.lang == 'en') {
          this.tonnageForm.openSeaareaId = item.id
          this.tonnageForm.openSeaareaCname = item.cname
          this.tonnageForm.openSeaareaEname = item.value
        } else {
          this.tonnageForm.openSeaareaId = item.id
          this.tonnageForm.openSeaareaCname = item.value
          this.tonnageForm.openSeaareaEname = item.ename
        }
        this.$refs.mychild.sealayer(this.tonnageForm.openSeaareaId, 'tonnage');
      },
      selectintentsea(item) {
        if(item.id == '-1'){
          this.tonnageForm.intentSeaareaName = ''
          return
        }
        this.tonnageForm.intentSeaareaId = item.id
        if (this.lang == 'en') {
          this.tonnageForm.intentSeaareaCname = item.cname
          this.tonnageForm.intentSeaareaEname = item.value
        } else {
          this.tonnageForm.intentSeaareaCname = item.value
          this.tonnageForm.intentSeaareaEname = item.ename
        }
      },
      selectintentcargo(item) {
        if(item.id == '-1'){
          this.tonnageForm.cargoName = ''
          return
        }
        if (this.lang == 'en') {
          this.tonnageForm.cargoId = item.id
          this.tonnageForm.cargoCname = item.cname
          this.tonnageForm.cargoEname = item.value
        } else {
          this.tonnageForm.cargoId = item.id
          this.tonnageForm.cargoCname = item.value
          this.tonnageForm.cargoEname = item.ename
        }
      },
      selectintentcountry(item) {
        if(item.id == '-1'){
          this.tonnageForm.intentCountryName = ''
          return
        }
        if (this.lang == 'en') {
          this.tonnageForm.intentCountryId = item.id
          this.tonnageForm.intentCountryCname = item.cname
          this.tonnageForm.intentCountryEname = item.value
        } else {
          this.tonnageForm.intentCountryId = item.id
          this.tonnageForm.intentCountryCname = item.value
          this.tonnageForm.intentCountryEname = item.ename
        }
      },
      //改变空船海域置空港口
      changeopenSeaareaName(value){
        if(value==''){
          this.tonnageForm.openSeaareaId=''
        }else{
          this.tonnageForm.openPortId= ''
          this.tonnageForm.openPortEname= ''
          this.tonnageForm.openPortCname= ''
          this.tonnageForm.openPortName= ''
          this.reselectsea()
        }
      },
      //改变意向海域置空港口
      changeintentSeaarea(value){
        if(value==''){
          this.tonnageForm.intentSeaareaId=''
        }else {
          this.tonnageForm.intentPortId = ''
          this.tonnageForm.intentPortEname = ''
          this.tonnageForm.intentPortCname = ''
          this.tonnageForm.intentPortName = ''
          this.reselectintentsea()
        }
      },
      //不点联想输入，船名
      changevesellname(){
        for(let i=0;i<this.vesselnamegroup.length;i++) {
          if (this.vesselnamegroup[i].value == this.tonnageForm.shipVesselName) {
            this.selectvessel(this.vesselnamegroup[i]);
            this.tonnageForm.shipVesselName=this.vesselnamegroup[i].value
            this.tonnageForm.built=this.vesselnamegroup[i].built
            this.tonnageForm.dwt=this.vesselnamegroup[i].dwt
            break
          }
        }
        this.tonnageForm.shipVesselCname=this.tonnageForm.shipVesselName
        this.tonnageForm.shipVesselEname=this.tonnageForm.shipVesselName
      },
      //不点联想输入，空船港口
      changeopenport(){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value.toString().toLowerCase() == this.tonnageForm.openPortName.toString().toLowerCase()||
              this.portnamegroup[i].ename!=undefined&&this.portnamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.openPortName.toString().toLowerCase()||
              this.portnamegroup[i].cname!=undefined&&this.portnamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.openPortName.toString().toLowerCase()) {
            this.selectopenport(this.portnamegroup[i]);
            this.tonnageForm.openPortName=this.portnamegroup[i].value
            break
          }
        }
        this.tonnageForm.openPortCname=this.tonnageForm.openPortName
        this.tonnageForm.openPortEname=this.tonnageForm.openPortName
      },
      //不点联想输入，意向港口
      changeintentport(){

        for(let i=0;i<this.portnamegroup.length;i++) {
          console.log(this.portnamegroup[i])
          if (this.portnamegroup[i].value.toString().toLowerCase() == this.tonnageForm.intentPortName.toString().toLowerCase()||
              this.portnamegroup[i].ename!=undefined&&this.portnamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.intentPortName.toString().toLowerCase()||
              this.portnamegroup[i].cname!=undefined&&this.portnamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.intentPortName.toString().toLowerCase() ) {
            this.selectintentport(this.portnamegroup[i]);
            this.tonnageForm.intentPortName=this.portnamegroup[i].value
            break
          }
        }
        this.tonnageForm.intentPortCname=this.tonnageForm.intentPortName
        this.tonnageForm.intentPortEname=this.tonnageForm.intentPortName
      },
      //不点联想输入，意向国家
      changeintentcountry(){
        for(let i=0;i<this.countrynamegroup.length;i++) {
          if (this.countrynamegroup[i].value.toString().toLowerCase() == this.tonnageForm.intentCountryName.toString().toLowerCase()||
              this.countrynamegroup[i].ename!=undefined&&this.countrynamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.intentCountryName.toString().toLowerCase()||
              this.countrynamegroup[i].cname!=undefined&&this.countrynamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.intentCountryName.toString().toLowerCase() ) {
            this.selectintentcountry(this.countrynamegroup[i]);
            this.tonnageForm.intentCountryName=this.countrynamegroup[i].value
            break
          }
        }
        this.tonnageForm.intentCountryCname=this.tonnageForm.intentCountryName
        this.tonnageForm.intentCountryEname=this.tonnageForm.intentCountryName
      },
      //不点联想输入，意向货物
      changeintentcargo(){
        for(let i=0;i<this.cargonamegroup.length;i++) {
          if (this.cargonamegroup[i].value.toString().toLowerCase() == this.tonnageForm.cargoName.toString().toLowerCase()||
              this.cargonamegroup[i].ename!=undefined&&this.cargonamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.cargoName.toString().toLowerCase()||
              this.cargonamegroup[i].cname!=undefined&&this.cargonamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.cargoName.toString().toLowerCase() ) {
            this.selectintentcargo(this.cargonamegroup[i]);
            this.tonnageForm.cargoName=this.cargonamegroup[i].value
            break
          }
        }
        this.tonnageForm.cargoCname=this.tonnageForm.cargoName
        this.tonnageForm.cargoEname=this.tonnageForm.cargoName
      },
      //不点联想输入也可以正常搜索，空船海域
      reselectsea(){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value.toString().toLowerCase() == this.tonnageForm.openSeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].ename!=undefined&&this.seanamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.openSeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].cname!=undefined&&this.seanamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.openSeaareaName.toString().toLowerCase()
          ) {
            this.selectopensea(this.seanamegroup[i]);
            this.tonnageForm.openSeaareaName=this.seanamegroup[i].value
            break
          }
        }
        this.tonnageForm.openSeaareaCname=this.tonnageForm.openSeaareaName
        this.tonnageForm.openSeaareaEname=this.tonnageForm.openSeaareaName
      },
    //不点联想输入也可以正常搜索，意向海域
      reselectintentsea(){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value.toString().toLowerCase() == this.tonnageForm.intentSeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].ename!=undefined&&this.seanamegroup[i].ename.toString().toLowerCase() == this.tonnageForm.intentSeaareaName.toString().toLowerCase()||
              this.seanamegroup[i].cname!=undefined&&this.seanamegroup[i].cname.toString().toLowerCase() == this.tonnageForm.intentSeaareaName.toString().toLowerCase()
          ) {
            this.selectintentsea(this.seanamegroup[i]);
            this.tonnageForm.intentSeaareaName=this.seanamegroup[i].value
            break
          }
        }
        this.tonnageForm.intentSeaareaCname= this.tonnageForm.intentSeaareaName
        this.tonnageForm.intentSeaareaEname= this.tonnageForm.intentSeaareaName
      },
      //选择空船港口自动关联海域
      selectopenport(item) {
        if(item.id == '-1'){
          this.tonnageForm.openPortName = ''
          return
        }
        this.tonnageForm.openPortId = item.id
        if (this.lang == 'en') {
          this.tonnageForm.openPortCname = item.cname
          this.tonnageForm.openPortEname = item.value
        } else {
          this.tonnageForm.openPortCname = item.value
          this.tonnageForm.openPortEname = item.ename
        }
        getseaarea(item.seaAreaId).then(res =>{
          this.tonnageForm.openSeaareaId = res.data.id
          this.tonnageForm.openSeaareaEname = res.data.ename
          this.tonnageForm.openSeaareaCname = res.data.cname
          if (this.lang == 'en') {
            this.tonnageForm.openSeaareaName = res.data.ename
          } else {
            this.tonnageForm.openSeaareaName = res.data.cname
          }
          this.$refs.mychild.sealayer(this.tonnageForm.openSeaareaId, 'tonnage');
          this.$refs.mychild.portimage(this.tonnageForm.openPortId,'tonnage');
        })

      },
    //选择意向港口自动关联海域
      selectintentport(item) {
        if(item.id == '-1'){
          this.tonnageForm.intentPortName = ''
          return
        }
        this.tonnageForm.intentPortId = item.id
        if (this.lang == 'en') {
          this.tonnageForm.intentPortCname = item.cname
          this.tonnageForm.intentPortEname = item.value
        } else {
          this.tonnageForm.intentPortCname = item.value
          this.tonnageForm.intentPortEname = item.ename
        }
        getseaarea(item.seaAreaId).then(res =>{
            this.tonnageForm.intentSeaareaId = res.data.id
            this.tonnageForm.intentSeaareaEname = res.data.ename
            this.tonnageForm.intentSeaareaCname = res.data.cname
              if (this.lang == 'en') {
                  this.tonnageForm.intentSeaareaName = res.data.ename
              } else {
                  this.tonnageForm.intentSeaareaName = res.data.cname
             }
        })
      },
      reloaddelete(){
        let params= new URLSearchParams()
        params.append('id',  this.tonnage_id)
        getFilelist(params).then(res =>{
           this.filesArray = res.data
        })
      },
      reset() {
        this.reload()
      }
    },

    mounted() {
  //     this.$nextTick(() => {
  //   const buttonElement = this.$refs.myButton.$el; // Access the underlying DOM element
  //   buttonElement.click(); // Trigger click on the DOM element
  // });
  //     this.$nextTick(() => {
  //   // 在下一次 DOM 更新循环结束之后执行
  //   if (this.$refs.tonnageForm) {
  //     // 如果元素存在，执行你的函数
  //     this.handleModify();
  //   }
  // });
 // this.handleModify();
 //this.tonnageForm.shipId = item.id
      this.areaname = this.$route.query.OpenAreaName;
      this.portname = this.$route.query.OpenPortName;
      this.vessel_names = this.vesselnames;
      this.isEorD = this.$route.params.eord;
      this.tonnage_id = this.$route.params.id;
      this.temp=this.$route.query.temp
      this.extract=this.$route.query.extract
      this.email_detail=this.$route.query.email_detail
      this.tonnageForm.emailDetailId=this.email_detail
      this.lang = localStorage.getItem('lang')
      if (this.isEorD != 1 && this.isEorD != 2) {
        this.tonnageForm.sendTime = new Date();
       // console.log(this.accountemail)
      // console.log(localStorage.getItem('accountemail'))

        this.tonnageForm.senderEmail = (this.accountemail=='portemail@163.com') ? '' : localStorage.getItem('accountemail')
        if(this.tonnageForm.senderEmail==''){
          ElMessageBox.alert(
              window.vm.$t('message.bangdingemail'),
              {
                confirmButtonText: window.vm.$t('message.OK'),
                type: 'info',
                showClose: false,
              }
          ).then(()=>{
            this.$router.push('/person/personalmessage/mymessage/1')
          })
        }
      }
      if (this.isEorD == 1 || this.isEorD == 2) {
        this.init_addtonnage()
      }
      this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
      if (this.buttonpermission.includes("62006")) {
        this.disabled = false;
      } else this.disabled = true;
      if (this.buttonpermission.includes("62006")) {
        this.editdisabled = false;
      } else this.editdisabled = true;
      if (this.buttonpermission.includes("62007")) {
        this.deletedisabled = false;
      } else this.deletedisabled = true;
      if (this.buttonpermission.includes("31000")) {
        this.matchdisabled = false;
      } else this.matchdisabled = true;
    }
  }
</script>

<style lang="scss">

 .highlight-pink {
  color: black;
  font-weight: bold;
  background-color: #FFC0CB;
}

.highlight-blue {
  color: black;
  font-weight: bold;
  background-color: #007BFF;
}
.highlight-red {
  color: black;
  font-weight: bold;
  background-color: red;
}

.highlight-green {
  color: black;
  font-weight: bold;
  background-color: #98FB98;
}

.highlight-orange {
  color: black;
  font-weight: bold;
  background-color: orange;
}

.highlight-purple {
  color: black;
  font-weight: bold;
  background-color: #c7aef8;
}
 .highlight {
  background-color: yellow;
  font-weight: bold;
  border: 1.3px solid black; /* 添加黑色框边 */
  padding: 2px; /* 可选，增加一些填充以改善视觉效果 */
 
}
/*.right-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 46.1%; /* 设置为页面的一半，可以根据需要调整 
  height: 100vh;
  background-color: white;
  border-left: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  z-index: 9999;  使其显示在其他元素之上，根据需要调整 
}*/
   // @import url('https://api.tiles.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css');
    .dd-detail{
        width:100%;
        height:100%;
        .add-tonnage{
            width:100%;
            height:100%;
            display: flex;
            .detail-left{
                width:50%;
                margin:1.5% 1.5% 1.5% 2%;
                background: pink;
                //height:100%;
                .box-card{
                    height:100%;
                    //margin-top: 1%;
                    //background: pink;
                    .el-card__header{
                        padding:6px 20px;
                        //background: #409eff;
                        height:10%;
                        .card-header{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            //background: pink;
                            height:100%;
                            span{
                                font-size: 16px;
                            }
                            .collect:hover{
                                cursor: pointer;
                            }
                            .collect{
                                width:25px;
                                height:25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                    .el-card__body{
                        height:90%;
                        overflow-y: auto;
                        .el-form{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            height:100%;
                            .el-form-item{
                                margin-bottom: 15px;
                            }
                            .el-checkbox-group{
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                            }
                            #btn-box{
                                margin-top: 20px;
                                //background: pink;
                                .el-form-item__content{
                                    display: flex;
                                    justify-content: space-around;
                                    margin-left:0 !important;
                                }
                            }
                            .detail-check{
                                margin-right: 20px;
                                i{
                                    color:#409eff;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
            .el-dialog__body {
                .el-textarea{
                    height:380px;
                }
                textarea.el-textarea__inner{
                    height: 100%;
                    width:100%;

                }
            }

            .detail-right{
                width:50%;
                //height:90%;
                margin:1.5% 2% 1.5% 1.5%;
                //background: pink;
            }
          #map{
                height:100%;
                width:100%;

                margin:1.5% 2% 1.5% 1.5%;
                //background: pink;
            }
        }
        .add-tonnage-mobile{
            position: relative;
            top:-6%;
            width:100%;
            height:100%;
            //display: flex;
            .detail-left{
                width:98%;
                margin-left: 1%;
                height:100%;
                .box-card{
                    height:95%;
                    margin-top: 10%;
                    //background: pink;
                    .el-card__header{
                        //padding:6px 20px;
                        .card-header{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            //background: pink;
                            height:25px;
                            span{
                                font-size: 16px;
                            }
                            .collect:hover{
                                cursor: pointer;
                            }
                            .collect{
                                width:25px;
                                height:25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                    .el-card__body{
                        height:100%;
                        overflow-y: auto;
                        .el-form-item{
                            margin-bottom: 15px;
                        }
                        .el-checkbox-group{
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                        }
                        #btn-box2{
                            margin-top: 25px;
                            //background: pink;
                            .el-form-item__content{
                                display: flex;
                                justify-content: space-around;
                                margin-left:0 !important;
                            }
                        }
                        .detail-check{
                            margin-right: 20px;
                            i{
                                color:#409eff;
                                margin-right: 5px;
                            }
                        }
                    }
                }


                //background: purple;
                // .box-card{
                //   height:100%;
                //   margin-top: 5%;
                //   .card-header{
                //     display: flex;
                //     justify-content: space-between;
                //     align-items: center;
                //     //background: pink;
                //     padding:6px 20px;
                //     span{
                //       font-size: 18px;
                //     }
                //     .collect:hover{
                //       cursor: pointer;
                //     }
                //     .collect{
                //       width:20px;
                //       height:20px;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //     }
                //   }
                //   .el-card__body{
                //     height:100%;
                //     display: flex;
                //     //background: slateblue;
                //     .card-left{
                //       width:60%;
                //       height:80%;
                //       //border:1px solid pink;
                //       display: flex;
                //       flex-direction: column;
                //       justify-content: space-around;
                //       .text{
                //         font-size: 15px;
                //       }
                //     }
                //   }
                // }
            }
            /*// .detail-right{*/
            /*//   height:50%;*/
            /*//   width:98%;*/
            /*//   margin:2% 1%;*/
            /*//   background: pink;*/
            /*// }*/
        }
    }
</style>
